/**
 * include
 */
function include(file) {
	var contents = eval('include_' + file);
	if (contents) {
		document.write(contents);
	}
}
// サイト内検索
function initSearchTool() {
	var da = document.createElement('script');
	da.type = 'text/javascript';
	da.async = true;
	if ('https:' === document.location.protocol) {
		da.src = 'https://rsv.dga.jp/s/nanaco/search_tool_n3.js';
	} else {
		da.src = 'http://cache.dga.jp/s/nanaco/search_tool_n3.js';
	}
	var sc = document.getElementsByTagName('script')[0];
	sc.parentNode.insertBefore(da, sc);
}
initSearchTool();
var loadJsFilesList = [
	'/material/js/lib/slick/slick.min.js',
	'/material/js/inc/inc_header.js',
	'/material/js/inc/inc_footer.js',
	'/material/js/inc/inc_sns.js',
	'/material/js/inc/inc_top_main_banner.js',
	'/material/js/inc/inc_top_shop.js',
	'/material/js/inc/inc_top_information.js',
	'/material/js/inc/inc_campaign_banners.js',
	'/material/js/inc/inc_campaign_pointcp.js',
	'/material/js/inc/inc_campaign_pickup.js',
	'/material/js/inc/inc_information_archive.js',
	'/material/js/inc/inc_seven_namda_bnr.js',
	'/material/js/inc/inc_seven_plus_bnr.js',
	'/material/js/inc/inc_seven_app_bnr.js',
	'/material/js/inc/inc_app_bt_hide.js',
	'/material/js/inc/inc_modal.js',
	'/material/js/inc/inc_line_twitter_bnr.js'
];

if (loadJsFilesList.length > 0) {
	for (var i in loadJsFilesList) {
		document.write('<script src="' + loadJsFilesList[i] + '"></script>');
	}
}
